import * as React from 'react';
import Layout from '../components/_global/Layout';
import Quiz from '../components/quiz/Quiz';
import { graphql } from 'gatsby';

const CatQuiz = ({ data }) => {
  const catQuestions = [
    {
      question: 'Jsi pořádkumilovný člověk?',
      answers: [
        'Ano, miluji, když je kolem mě čisto. ',
        'Ani ne, neřeším, kde co leží. ',
      ],
      response: [
        'Jsem potěšena. Užij si ten pocit, protože se nebude opakovat často. Je třeba kolem mě denně uklízet, ale tobě to nebude dělat problém. Jen doufám, že nelpíš na perfektním pořádku, protože ten se mnou trvá tak 5 minut.',
        'Pch, to se dalo čekat. Já jsem ale tvor, který miluje pořádek. Vždy se udržuji perfektně čistá, ale udržet pořádek kolem mně bude na tobě. Čeká tě hlavně spousta chlupů, čištění kočičí toalety a občas i ráda hrabu, co se dá. Třeba hlínu z květináčů. A ať nenecháš nikde nic, co bych mohla sežrat! Nezapomeň, že tě budu neustále sledovat!',
      ],
      correct: 0,
      background: '#89cddc',
    },
    {
      question: 'Máš doma dost místa?',
      answers: [
        'Ano, pokud jde o nábytek a dekorace, preferuji minimalismus. ',
        'Vůbec ne, miluji designový nábytek a dekorace.',
      ],
      response: [
        'Hmm, lidé, vždy tak minimální! Ale když bude dost místa na mé prolézačky, škrabadla a hračky, tvůj nábytek bude v bezpečí před mými drápky. A dekorace? Občas nějakou shodím. „Omylem“, pochopitelně.',
        'To já také! Dekorace zbožňuji. Ráda si s nimi hraji… a shazuji je. Jsem prostě šelma se svými pudy a potřebami. A pokud se nenajde místo na moje prolézačky a škrabadla, může se stát, že tvůj designový nábytek „vylepším“ svými drápky. A hračky si udělám z věcí, co máš v policích.',
      ],
      correct: 0,
      background: '#89cddc',
    },
    {
      question: 'Považuješ se za vůdčí typ?',
      answers: [
        'Ano! Chci mít věci pod kontrolou.',
        'Ne, jsem spíš týmový hráč.',
      ],
      response: [
        'To je dobře, ale nezapomeň, že v mém týmu se hraje podle mých pravidel.',
        'Než se sestěhujeme, musíš vědět, že u mě doma se hraje podle mých pravidel. Neudělám vždycky to, co budeš chtít. Nejsem pes. Někdy tě třeba budu i úplně ignorovat.',
      ],
      correct: 1,
      background: '#89cddc',
    },
    {
      question: 'Jsi nadšenec do módy?',
      answers: [
        'Na poslední trendy si příliš nepotrpím.',
        'Ano! V módních trendech se vyžívám.',
      ],
      response: [
        'Skvěle! Pak ti snad nebude vadit, že tvé oblečení bude většinou samý chlup a někdy možná i trochu potrhané.',
        'Víš, že když zrovna dostanu chuť se přitulit, nebudu čekat, než se převlékneš z designového outfitu do pyžama? Pak se může stát, že tvoje večerní róba bude samý chlup a samá díra od mých drápků. Ale to už je tvůj lidský úděl.',
      ],
      correct: 0,
      background: '#89cddc',
    },
    {
      question: 'Vyděláváš tolik, že si můžeš dovolit náročnou společnost?',
      answers: [
        'Většinou žiju od výplaty k výplatě.',
        'Jasně, něco ušetřit pro mě není problém.',
      ],
      response: [
        'Pak tě asi nějaký ten výdaj navíc nepřekvapí. Mít kočičí dámu – nebo kočičího pána, to není jen o kupování krmiva. I když je pravda, že plnou miskou nikdy nepohrdnu.',
        'Možná si říkáš, že jeden chlupatý hladový krk navíc zvládneš, ale mysli i na to, že to není jen o nákupu kočičího krmiva. Škrabadla, očkování, pelíšek, škrabadla, pamlsky, hračky, škrabadla, kočičí hotely, když nenajdeš nikoho, kdo by mě pohlídal… A to nemluvím o tom, kolik stojí ošetření, kdyby se mi něco stalo. A co když něco zničím? I to se může stát. A už jsem zmiňovala škrabadla?',
      ],
      correct: 1,
      background: '#89cddc',
    },
    {
      question: 'Kýcháte u vás doma hodně?',
      answers: ['Ano, máme to v rodině.', 'Pokud vím, tak ne.'],
      response: [
        'To je dobře. A nediv se té otázce. Lidé citlivější na alergeny by mou přítomnost mohli špatně snášet.',
        'Nediv se tak, žádnou otázku nepokládám zbytečně. Lidé, kteří jsou citliví na alergeny, by mohli v mé přítomnosti nepatřičně zrudnout a kýchat. Zvaž proto, jestli se neotestovat, než si mě pořídíš.',
      ],
      correct: 1,
      background: '#89cddc',
    },
    {
      question: 'Víš, kam se vypravit pro novou kočičí společnost?',
      answers: [
        'Podívám se na specializované chovatelské weby a pak se obrátím na prověřené chovatele.',
        'Dám na svou intuici, projdu inzeráty na internetu a vyberu prodejce s nejlepší cenou.',
      ],
      response: [
        'To je správně! Kdyby všichni uvažovali jako ty, nekončilo by nás tolik na ulicích.',
        'Pch, myslíš, že je dobré kupovat ušlechtilé zvíře a společníka na dlouhou část života pod cenou? Internet je plný inzerátů množíren, kterým jde jen o zisk. My, kočky, které jsme v nich drženy, tam mnohdy trpíme v nevyhovujících podmínkách. Uděláš lépe, kdyby se ujmeš některé z těch, které skončily v útulku. Anebo zajdeš za prověřeným chovatelem.',
      ],
      correct: 0,
      background: '#89cddc',
    },
    {
      question: 'Máš hodně koníčků?',
      answers: [
        'No jasně, od rána jsem v jednom kole a domů se vracím až pozdě večer.',
        'Pár ano, ale většinou se jedná o věci, které dělám doma. ',
      ],
      response: [
        'To ráda slyším. I když se někdy může zdát, že o tebe nejevím zájem, jsem ráda, když tě vidím a cítím nablízku.',
        'A co já? Někdy se sice může zdát, že o kontakt s člověkem příliš nestojím, ve skutečnosti jsem ale ráda, když tě mám nablízku. Nebaví mě trávit celé dny sama. ',
      ],
      correct: 1,
      background: '#89cddc',
    },
    {
      question: 'Dokážeš akceptovat, že občas někdo nestojí o tvou pozornost?',
      answers: [
        'Ano, nemám ve zvyku se vnucovat.',
        'Ne, zvíře přece mohu hladit, kdykoliv se mi zachce.',
      ],
      response: [
        'Pak by nám spolu mohlo být dobře. Já nemám ráda, když mě lidi chtějí hladit a mě se na můj názor neptají.',
        'No, možná by bylo lepší tenhle názor přehodnotit. Mně je sice s člověkem dobře, ale musím to být já, kdo si přijde pro pohlazení. Víš, kočka není věc, ale živý tvor se svými potřebami, pocity a náladami.',
      ],
      correct: 0,
      background: '#89cddc',
    },
    {
      question:
        'Chápeš, že když se sestěhujeme, bude to do mého prostředí, i když byt bude tvůj?',
      answers: [
        'Ano, vím, že kočky jsou silné osobnosti.',
        'Ne. Zvíře, které si kupuji, bude přece žít v mém bytě.',
      ],
      response: [
        'Překvapivě správná odpověď, na člověka. Myslím, že bychom to spolu mohli zkusit.',
        'Možná si ještě rozmysli, jestli mě opravdu chceš, aby pak nepřišlo zklamání. Já nejsem věc (a už vůbec ne pes!) a umím to dát jasně najevo.',
      ],
      correct: 0,
      background: '#89cddc',
    },
    {
      question: 'Cestuješ často?',
      answers: ['Jasně, cestuju pořád!', 'Ani ne.'],
      response: [
        'Přijatelná odpověď. Častá změna prostředí nedělá dobře nejen mé srsti a náladě, ale hlavně zdraví. Kočkám zkrátka nedělá dobře stres ze změn.',
        'Cestování se mnou nebude tak jednoduché, kočičí dámy většinou nesnáší změny prostředí příliš dobře. Zjisti si raději, jestli máš v okolí někoho, kdo bude ochotný za mnou každý den chodit. No ano, ani my kočky nechceme být dlouho o samotě.',
      ],
      correct: 1,
      background: '#89cddc',
    },
  ];

  const finalTexts = [
    {
      text:
        'Mňau! Vypadá to, že bych se v naší společné domácnosti cítila dobře. Možná je načase začít přemýšlet o tom, pro jaké kočičí plemeno se budeš nejlépe hodit, protože my kočky se docela hodně lišíme nejen svým vzhledem, ale i temperamentem a tím, jak moc se o nás musíš starat. ',
    },
    {
      text:
        'Být společníkem kočky není vůbec snadná věc a žádná z nás nechce strávit roky života po boku někoho, kdo ještě není připravený. Zkus navštívit útulek nebo chovatelskou stanici, přečíst si pár článků nebo knih nebo si popovídat s odborníky v útulku či chovateli na nějaké výstavě. Získáš tak lepší představu o tom, co to znamená sdílet domácnost s kočkou.',
    },
  ];

  return (
    <Layout>
      <Quiz
        questions={catQuestions}
        images={data.allFile}
        minimumCorrectAnswers={6}
        finalScreen={finalTexts}
      />
    </Layout>
  );
};

export const query = graphql`
  query imagesCatQuiz {
    allFile(filter: { dir: { regex: "/(kviz-kocky)/" } }) {
      nodes {
        childImageSharp {
          fixed {
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 500) {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
            base64
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default CatQuiz;
